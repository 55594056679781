<template>
  <div class="vg_wrapper">
    <el-card>
     <div class="vd_button_group">
      <el-button type="primary" @click="submit('deptForm')" size="small">保存</el-button>
      <el-button @click="cancel" size="small">取消</el-button>
    </div>
     <el-form ref="deptForm" :model="deptForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="部门名称" prop="dept_name">
            <el-input v-model="deptForm.dept_name" maxlength="10" show-word-limit placeholder="请填写部门名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" :offset="4">
          <el-form-item label="部门简介" prop="dept_brief">
            <el-input v-model="deptForm.dept_brief" :autosize="{ minRows: 2, maxRows: 6 }" @input="deptForm.dept_brief=helper.keepSpace(deptForm.dept_brief)" maxlength="50" show-word-limit placeholder="请填写部门简介" type="textarea" :rows="1"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_button">
        <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
        <el-button type="danger" plain size="small" @click="delTeam()">删除</el-button>
      </div>

      <el-table ref="team" :data="deptForm.dept_team_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="48" align="center"></el-table-column>
        <el-table-column label="序号" width="48" align="center">
          <template slot-scope="scope">
            {{ scope.$index+1 }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vd_title">小组名称</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'dept_team_list.'+scope.$index+'.dept_team_name'" :rules="[{required:true}]">
              <el-input v-model="deptForm.dept_team_list[scope.$index].dept_team_name" maxlength="30" show-word-limit placeholder="请填写该部门包含的小组名称（必填）"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="vg_mt_16">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    </el-card>
  </div>
</template>

<script>
import {post} from "@api/request";
import {deptAPI} from "@api/modules/department";
import inputUser from "@/views/component/inputUser";
export default {
  name: 'DeptAdd',
  components: {
    inputUser
  },
  data() {
    return {
      deptForm: {
        dept_name: '',
        dept_brief: '',
        key: 0,
        dept_team_list:[{
          dept_team_name: null
        }]
      },
      selectionsList: [],
      rules:{
        dept_name:[{required: true, trigger: 'blur', message: ' '}]
      }
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      })
      .catch(()=>{})
    },
    // 保存
    saveInfo() {
      post(deptAPI.addDept,this.deptForm)
      .then(res => {
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          this.jump('/dept_edit',{perm_id:this.$route.query.perm_id,form_id:res.data.data.form_id})
          this.resetForm('deptForm')
        }else if(res.data.code === 7){
          this.$message({message: '该部门名称已存在', type: 'error'});
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('deptForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      })
      .catch(()=>{})
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //添加
    addRow() {
      let item = {
        dept_team_name: '',
        key: this.deptForm.key
      }
      this.deptForm.key = this.deptForm.key +1
      this.deptForm.dept_team_list.push(item);
    },
    //
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection
    },
    //多选删除
    delTeam() {
      if(this.selectionsList.length>0){
        if(this.deptForm.dept_team_list.length>1 && this.deptForm.dept_team_list.length-this.selectionsList.length>0){
          this.$confirm('确定取消删除?', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning'
          }).then(() => {
            for(let i = 0 ; i < this.selectionsList.length; i++) {
              this.deptForm.dept_team_list.forEach((item)=>{
                if(item.key === this.selectionsList[i].key) {
                  let currentIndex = this.deptForm.dept_team_list.indexOf(item);
                  this.deptForm.dept_team_list.splice(currentIndex,1);
                }
              });
            }
          })
          .catch(()=>{})
        }else{
          this.$message.warning('至少保留一个小组')
        }
      }else{
        this.$message.warning('至少选择一条数据')
      }
    },
  }
};
</script>

<style scoped lang="scss">
.vd_button{
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.vd_title{
  color: $color-deep-red;
}
.vg_wrapper ::v-deep.el-form-item{
  margin: 0;
  padding: 0;
}



</style>